import React from "react";
import logo from "../images/logo.svg";

const IndexPage = () => {
  return (
    <>
      <div className="text-center py-16 place-content-center grid">
        <div className="flex flex-row ">
          <img src={logo} className="w-24 text-bottom align-text-bottom" />
        </div>
        <div className="flex flex-row">
          Er under konstruksjon<span className="text-3xl">🏗️</span>
        </div>
      </div>
    </>
  );
};
export default IndexPage;
